<template>
  <div>
    <div class="row justify-content-start align-items-center">
      <Buscar :delay="1000" :disabled="loading" opened :onFiltrar="onFiltrar" />
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <FilterAlphabet :disabled="loading" v-model="filters.alfabeto" />
      </div>
    </div>

    <b-form-checkbox
      class="d-flex justify-content-end mt-2"
      v-model="filters.status"
      :value="1"
      :unchecked-value="-1"
    >
      Somente Ativos</b-form-checkbox
    >

    <div class="d-flex justify-content-between align-items-center ">
    <div class="flex-grow-1">
        <b-tabs :value="tabs.indexOf(filters.tipo)">
        <b-tab
          :disabled="loading"
          :title="normalizaTitulo(tab)"
          @click="setTipo(tab)"
          v-for="(tab, i) in tabs"
          :key="`${tab}-${i}`"
        >
        </b-tab>
      </b-tabs>
    </div>
      <div >
        <b-btn
        :disabled="loading"
        @click="carregar"
        class="mt-2 ml-auto"
  
        variant="secondary"
      >
        <i v-if="!loading" class="fas fa-sync-alt"></i>
        <b-spinner small v-if="loading"></b-spinner> 
      </b-btn>
      </div>
    </div>

    <b-table
      :busy="loading"
      show-empty
      hover
      selectable
      :select-mode="selectMode"
      small
      outlined
      :items="listaComputada"
      :fields="campos"
      class="shadow my-2"
      style="font-size: 15px;"
      ref="tableExames"
      @row-dblclicked="onSelecionar"
      @row-selected="onRowSelected"
    >
      <template #empty>
        <div class="text-center text-muted">
          Nada aqui... <i class="fas fa-frown"></i>
        </div>
      </template>

      <template #table-busy>
        <div class="d-flex justify-content-center">
          <h4><b-icon animation="cylon" icon="search"></b-icon> Buscando...</h4>
        </div>
      </template>

      <template #cell(razao_social)="{item}">
        <div class="">
          {{ item.razao_social }}
        </div>
      </template>

      <template #cell(telefones)="row">
        <div
          v-for="tel in row.item.telefones"
          :key="`telefone_${row.item.telefones.indexOf(tel)}`"
          class="d-block text-truncate"
        >
          {{ tel.telefone }}
        </div>
      </template>
      <template #cell(endereco)="row">
        <div
          v-for="end in row.item.enderecos"
          :key="`endereco_${row.item.enderecos.indexOf(end)}`"
          class="d-block text-truncate"
        >
          {{ end.cidade }} - {{ end.uf }}
        </div>
      </template>
      <template #cell(status)="row">
        <div
          :class="[
            ' text-truncate badge',
            row.item.status == 1
              ? 'badge-pill badge-primary'
              : 'badge-pill badge-secondary',
          ]"
        >
          {{ row.item.status === 1 ? "Ativo" : "Inativo" }}
        </div>
      </template>
      <template #cell(especialidadesOBJ)="row">
        <div class="text-center">
   
          <div
            v-for="espec in row.item.especialidadesOBJ"
            :key="`${row.item.id}_${espec.especialidade}`"
            class=" text-truncate badge badge-pill badge-primary mb-1"
          >
            <i class="fa fa-stethoscope mr-1"></i>{{ espec.especialidade }}
          </div>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="paginacao.pagina_atual"
      :total-rows="paginacao.total_registros"
      :per-page="paginacao.registros_por_pagina"
    >
    </b-pagination>
    <div class="row border-top pt-3 mt-4" v-if="showButtons">
      <div class="col d-flex justify-content-end">
        <b-btn
          :disabled="loading"
          @click="onSelecionar"
          variant="primary"
          class="mr-3"
        >
          <div>
            <i v-if="!loading" class="fas fa-plus"></i>
            <b-spinner v-else small></b-spinner> Selecionar
          </div>
        </b-btn>
        <b-btn @click="$emit('close')" variant="secondary">
          <i class="fas fa-ban"></i> Fechar
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Buscar from "./Buscar.vue";
import EntidadesLib from "../../libs/EntidadesLib";
import FilterAlphabet from "./FilterAlphabet.vue";
import { tiposDeEntidade } from "../../helpers/tiposDeEntidade";
export default {
  components: {
    Buscar,
    FilterAlphabet,
  },
  props: {
    showButtons: {
      type: Boolean,
      default: true,
    },
    withoutConvenio: {
      type: Boolean,
      default: false,
    },
    selectMode: {
      type: String,
      default: "single",
    },
    tabs: {
      type: Array,
      default: () => tiposDeEntidade,
    },
  },
  data() {
    return {
      campos: [],
      paginacao: {
        registros_por_pagina: 12,
        pagina_atual: 1,
        total_registros: 0,
      },
      lista: [],
      filters: {
        busca: "",
        tipo: this.tabs[0],
        alfabeto: "",
        status: 1,
      },
      selected: {},
      loading: false,
    };
  },
  methods: {
    setTipo(tipo) {
      this.filters.tipo = tipo;
    },
    camposPara() {
      const medico = [
        { key: "razao_social", label: "Médico", class: "text-left" },
        { key: "cpf", label: "CPF/CNPJ" },
        {
          key: "especialidadesOBJ",
          label: "Especialidades",
          class: "text-center",
        },
      ];

      const paciente = [
        {
          key: "razao_social",
          label: "Paciente",
          sortable: true,
          class: "text-left",
        },
        { key: "cpf", label: "CPF/CNPJ" },
        {
          key: "convenioNome",
          label: "Convênio",
          sortable: true,
          class: "text-center",
        },
        // { key: "especialidadesOBJ", label: "Especialidades", sortable: true },
      ];
      const funcionario = [
        {
          key: "razao_social",
          label: "Funcionário",
          sortable: true,
          class: "text-left",
        },
        { key: "cpf", label: "CPF/CNPJ" },
        { key: "convenioNome", label: "Convênio", class: "text-center" },
        // { key: "especialidadesOBJ", label: "Especialidades", sortable: true },
      ];

      const convenio = [
        { key: "razao_social", label: "Empresa", class: "text-left" },
      ];
      const fornecedor = [
        { key: "razao_social", label: "Empresa", class: "text-left" },
      ];
      const laboratorio = [
        { key: "razao_social", label: "Empresa", class: "text-left" },
      ];

      return {
        medico,
        paciente,
        convenio,
        fornecedor,
        laboratorio,
        funcionario,
      };
    },
    carregaCampos() {
      // console.log(this.camposPara()[this.tipo])

      this.campos = [
        this.campos[0],
        ...this.camposPara()[this.filters.tipo],
        ...this.campos.splice(1, this.campos.length),
      ];
    },
    normalizaTitulo(title) {
      return typeof title == "string"
        ? `${title.charAt(0).toUpperCase()}${title.slice(1)}${
            ["r", "R"].includes(title[title.length - 1]) ? "es" : "s"
          }`
        : "";
    },
    onSelecionar(item = null) {
      // console.log('item doubleClicked', item);
      if (item.status === 0) {
        this.showToast("error", `Esse médico está inativado.`, 3500);
        return;
      }
      if (item?.id) this.selected = Object.assign(this.selected, item);
      if (!this.selected.id) {
        this.showToast(
          "error",
          `Nenhum ${this.filters.tipo} selecionado.`,
          3500
        );
        return;
      }
      this.$emit("input", this.selected);
      this.$emit("close");
    },
    onFiltrar(filtro) {
      this.filters.busca = filtro;
    },
    onRowSelected(items) {
      if (items.some((e) => e.status === 0)) {
        this.$refs.tableExames.clearSelected();
        return;
      }

      console.log(items);
      if (items.length === 0) return;
      this.selected = items[0];
    },
    async carregar() {
      this.loading = true;
      let result = await EntidadesLib.get(
        this.filters.tipo,
        this.paginacao,
        null,
        {
          geral: this.filters.busca,
          alfabeto: this.filters.alfabeto,
          withoutConvenio: this.withoutConvenio,
          status: this.filters.status,
        }
      );
      //console.log("result", result);
      this.lista = result.entidades;
      this.paginacao = Object.assign(this.paginacao, {
        ...result.pag,
        pagina_atual: this.paginacao.pagina_atual,
      });

      this.loading = false;
    },
  },
  mounted() {
    this.$nextTick();
    this.filters.tipo = this.tabs[0];
    this.carregaCampos();
    this.carregar();
  },
  computed: {
    listaComputada() {
      return this.lista.map((ent) => {
        if (ent.status === 0) {
          return { ...ent, _rowVariant: "danger" };
        }
        return ent;
      });
    },
  },
  watch: {
    filters: {
      deep: true,
      async handler() {
        this.carregar();
      },
    },
    "filters.tipo": {
      handler() {
        this.carregaCampos();
      },
    },
    "filters.busca": {
      handler(newV, oldV) {
        if (this.filters.alfabeto && newV.trim().length > oldV.trim().length) {
          this.filters.alfabeto = "";
        }
       
          this.carregar();
        
      },
    },
    "paginacao.pagina_atual": {
      async handler() {
        this.carregar();
      },
    },
  },
};
</script>

<style></style>
