var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",class:_vm.customClass},[_c('div',{class:`pr-1 col-${12 -
        _vm.showBotaoSearch -
        (_vm.pacienteSel &&
        _vm.pacienteSel.id &&
        _vm.pacienteSel.id > 0 &&
        _vm.showBotaoEdit &&
        _vm.selecionado
          ? 1
          : 0) -
        _vm.showBotaoRemove}`},[_c('vue-simple-suggest',{ref:"select",attrs:{"list":_vm.pacienteLista,"styles":_vm.autoCompleteStyle,"disabled":_vm.disabled,"filter-by-query":false,"autocomplete":"off","min-length":_vm.minLength,"debounce":500,"display-attribute":"razao_social","mode":"select","controls":{
        selectionUp: [38, 33],
        selectionDown: [40, 34],
        select: [13, 36],
        showList: [40],
        hideList: [27, 35, 13],
      }},on:{"select":function($event){_vm.$emit('select', $event);
        _vm.onSelect($event);},"input":_vm.onInput,"request-start":function($event){_vm.loading = true},"request-done":function($event){_vm.loading = false}},scopedSlots:_vm._u([{key:"suggestion-item",fn:function({ suggestion }){return _c('div',{},[_c('div',[_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(suggestion.razao_social)+" ")]),(!suggestion.cpf)?_c('div',{staticClass:"text-center bg-light"},[_c('h5',{staticClass:"text-danger"},[_vm._v("Cadastro incompleto")])]):_vm._e(),(suggestion.idade && !_vm.is['medico'])?_c('small',[_vm._v("("+_vm._s(suggestion.idade)+" anos)")]):_vm._e(),(
              suggestion.cpf ||
                (Array.isArray(suggestion.especialidadesOBJ) &&
                  suggestion.especialidadesOBJ.length > 0)
            )?_c('br'):_vm._e(),_c('small',[_c('div',{staticClass:"d-flex justify-content-between"},[(suggestion.cpf && !_vm.is['medico'])?_c('small',[_vm._v("Doc.: "+_vm._s(suggestion.cpf))]):_vm._e(),(
                  _vm.is['medico'] &&
                    Array.isArray(suggestion.especialidadesOBJ) &&
                    suggestion.especialidadesOBJ.length > 0
                )?_c('small',[_vm._v(" "+_vm._s(suggestion.especialidadesOBJ .map((e) => e.especialidade) .join(", "))+" ")]):_vm._e(),(
                  suggestion.telefones &&
                    suggestion.telefones.length > 0 &&
                    !_vm.is['medico']
                )?_c('small',[_vm._v("Fone: "+_vm._s(suggestion.telefones[0].telefone))]):_vm._e(),(suggestion.crm && _vm.is['medico'])?_c('small',[_c('b',[_vm._v("CRM: ")]),_vm._v(" "+_vm._s(suggestion.crm)+" ")]):_vm._e()]),(
                Array.isArray(suggestion.dependenteDe) &&
                  suggestion.dependenteDe.length > 0
              )?_c('div',[(
                  Array.isArray(suggestion.dependenteDe) &&
                    suggestion.dependenteDe.length > 0
                )?_c('small',{},[_vm._v(" Dependente de: "),_c('b',[_vm._v(_vm._s(suggestion.dependenteDe.map((d) => d.titular).join(", ")))])]):_vm._e()]):_vm._e()])])])}}]),model:{value:(_vm.pacienteSel),callback:function ($$v) {_vm.pacienteSel=$$v},expression:"pacienteSel"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pacienteSel.razao_social),expression:"pacienteSel.razao_social"}],staticClass:"form-control optional-custom-input",attrs:{"autocomplete":"off","id":`txtField${Math.random()}`,"name":`txtField${Math.random()}`},domProps:{"value":(_vm.pacienteSel.razao_social)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pacienteSel, "razao_social", $event.target.value)}}})])],1),_c('div',{class:`col-${_vm.showBotaoSearch +
        (_vm.pacienteSel &&
          _vm.pacienteSel.id &&
          _vm.pacienteSel.id > 0 &&
          _vm.showBotaoEdit &&
          _vm.selecionado) +
        _vm.showBotaoRemove} pl-0`},[(
        _vm.pacienteSel &&
          _vm.pacienteSel.id &&
          _vm.pacienteSel.id > 0 &&
          _vm.showBotaoEdit &&
          _vm.selecionado
      )?_c('span',[_c('b-button',{attrs:{"disabled":_vm.disabled || _vm.loading,"variant":"primary","size":"md"},on:{"click":function($event){return _vm.$emit('edit', _vm.pacienteSel)}}},[(!_vm.loading)?_c('i',{staticClass:"fa fa-edit"}):_vm._e(),(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1):_vm._e(),(_vm.showBotaoAdd)?_c('span',[_c('b-button',{attrs:{"disabled":_vm.disabled || _vm.loading,"variant":"primary","size":"md"},on:{"click":function($event){return _vm.$emit('new')}}},[(!_vm.loading)?_c('b-icon-person-plus'):_vm._e(),(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1):_vm._e(),(_vm.showBotaoSearch)?_c('span',[(!_vm.selecionado)?_c('b-button',{attrs:{"disabled":_vm.disabled || _vm.loading,"variant":"primary","size":"md"},on:{"click":_vm.openSearch}},[(!_vm.loading)?_c('i',{staticClass:"fas fa-search"}):_vm._e(),(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1):_vm._e(),(_vm.selecionado && _vm.showBotaoRemove)?_c('b-button',{attrs:{"disabled":_vm.disabled || _vm.loading,"variant":"primary","size":"md"},on:{"click":_vm.deselect}},[(!_vm.loading)?_c('i',{staticClass:"fas fa-times"}):_vm._e(),(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1):_vm._e()],1):_vm._e()]),_c('b-modal',{attrs:{"hide-backdrop":_vm.hideBackdrop,"size":_vm.searchModalSize,"hide-footer":"","id":`modalSearch${_vm.tipo}`},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('i',{staticClass:"fas fa-search"}),_vm._v(" Buscar "+_vm._s(_vm.searchName)+" ")]},proxy:true}])},[_c('search-entidades',{attrs:{"tabs":_vm.tipo,"withoutConvenio":_vm.withoutConvenio},on:{"input":function($event){return _vm.onSelect($event)},"close":_vm.closeSearch}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }